import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../config/firebaseConfig';


export const useAuth = () => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsSignedIn(!!user);
        });
        
        return () => unsubscribe();
    }, []);
    
    return { isSignedIn };
};

export const currentUser = auth.currentUser;