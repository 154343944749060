import { useState, useEffect } from "react";
import { deleteImage, getImages } from "../controllers/productsController";

const Gallery = () => {

    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const fetchedImages = await getImages();
            setImages(fetchedImages);
            setLoading(false);
        };
        fetchImages();
    }, []);

    if(loading){
        return (
            <div className="container">
                <h3 className="placeholder">Gallery</h3>
                <div className="row">
                    {Array(12).fill().map((_, index) => (
                        <div className="col-md-3" key={index}>
                            <div className="card placeholder-glow m-1 p-1">
                                <img className="placeholder" src="..."/>
                                <h4 className="placeholder">Item Name</h4>
                                <button className="placeholder btn btn-primary disabled w-50"></button>
                                <button className="placeholder btn btn-danger disabled w-50"></button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }else{
        return (
            <div className="container my-4">
              <h3 className="text-center mb-4">Gallery</h3>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {images.map((image, index) => (
                  <div key={index} className="col">
                    <div className="card h-100">
                      <img
                        src={image.url}
                        className="card-img-top"
                        alt={image.name}
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title">{image.name}</h5>
                        <div className="mt-auto">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => {
                              navigator.clipboard.writeText(image.url);
                            }}
                          >
                            Copy Url
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              deleteImage(image.name);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );          
    }

}

export default Gallery;