const SpecialsForm = ({
    specialDetails,
    setSpecialDetails,
    handleSave,
    handleCancel,
    isEditMode = false
}) => {
    return (
        <div className="container mt-4">
            <form className="p-3 border rounded bg-light m-1">
                <h4 className="mb-3">{isEditMode ? "Edit Special" : "Add New Special"}</h4>
                
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input
                        id="title"
                        type="text"
                        className="form-control"
                        value={specialDetails.title}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, title: e.target.value })}
                        placeholder="Enter title"
                    />
                </div>
                
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea
                        id="description"
                        className="form-control"
                        value={specialDetails.description}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, description: e.target.value })}
                        placeholder="Enter description"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">Start Date</label>
                    <input
                        id="startDate"
                        type="date"
                        className="form-control"
                        value={specialDetails.startDate}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, startDate: e.target.value })}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="endDate" className="form-label">End Date</label>
                    <input
                        id="endDate"
                        type="date"
                        className="form-control"
                        value={specialDetails.endDate}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, endDate: e.target.value })}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="imageRef" className="form-label">Image URL</label>
                    <input
                        id="imageRef"
                        type="text"
                        className="form-control"
                        value={specialDetails.imageRef}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, imageRef: e.target.value })}
                        placeholder="Enter image URL"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="minRequirements" className="form-label">Minimum Price Required</label>
                    <input
                        id="minRequirements"
                        type="number"
                        className="form-control"
                        value={specialDetails.minRequirements}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, minRequirements: e.target.value })}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="minCartItems" className="form-label">Minimum cart items</label>
                    <input
                        id="minCartItems" 
                        type="number" 
                        className="form-control"
                        value={specialDetails.minCartItems}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, minCartItems: e.target.value })} />
                </div>

                <div className="mb-3">
                    <label htmlFor="appliedDiscount" className="form-label">Discount to be applied</label>
                    <input
                        id="appliedDiscount"
                        type="number"
                        className="form-control"
                        value={specialDetails.appliedDiscount}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, appliedDiscount: e.target.value })}
                    />
                </div>

                <div className="mb-3 form-check">
                    <input
                        id="isActive"
                        type="checkbox"
                        className="form-check-input"
                        checked={specialDetails.isActive}
                        onChange={(e) => setSpecialDetails({ ...specialDetails, isActive: e.target.checked })}
                    />
                    <label htmlFor="isActive" className="form-check-label">Active</label>
                </div>

                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-primary me-2" onClick={handleSave}>
                        {isEditMode ? "Save Changes" : "Add Special"}
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default SpecialsForm;