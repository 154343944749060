import { useState, useEffect } from "react";
import { db } from "../../config/firebaseConfig"; 
import { collection, query, where, getDocs } from "firebase/firestore";
import ProductCard from "../reusables/ProductCard";
import { addToCart, getCart, increaseQuantity, decreaseQuantity } from "../../utils/cartUtils";
import Products from "./Products";
import "../../styles/Products.css";

const Specials = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);

useEffect(() => {
    const fetchSpecials = async () => {
        try {
            const q = query(collection(db, "products"), where("discount", ">", 0));
            const querySnapshot = await getDocs(q);
            const specials = [];
            querySnapshot.forEach((doc) => {
                specials.push({ id: doc.id, ...doc.data() });
            });
            setProducts(specials);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching specials: ", error);
        }
    };

    fetchSpecials();
}, []);


    const handleAddToCart = (product) => {
        addToCart(product);
        setCart(getCart());
    };

    const handleIncreaseQuantity = (productId) => {
        increaseQuantity(productId);
        setCart(getCart());
    };

    const handleDecreaseQuantity = (productId) => {
        decreaseQuantity(productId);
        setCart(getCart());
    };

    const isInCart = (productId) => {
        return cart.some(product => product.id === productId);
    };

    const getProductQuantity = (productId) => {
        const product = cart.find(item => item.id === productId);
        return product ? product.quantity : 0;
    };

    if (loading) {
        return (
          <div className="container">
            <div className="row">
              {Array(12).fill().map((_, index) => (
                <div className="col-md-3" key={index}>
                  <div className="card placeholder-glow m-1 p-1">
                    <img className="placeholder" src="..." alt="placeholder" />
                    <h4 className="placeholder">Item Name</h4>
                    <button className="placeholder btn btn-primary disabled w-50"></button>
                    <button className="placeholder btn btn-danger disabled w-50"></button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }else{
          return (
              <div className="container">
                  <h3>Specials</h3>
                  <div className="product-grid">
                      {products.length > 0 ? (
                          products.map(product => (
                              <ProductCard
                                  key={product.id}
                                  product={product}
                                  isInCart={isInCart}
                                  handleDecreaseQuantity={handleDecreaseQuantity}
                                  getProductQuantity={getProductQuantity}
                                  handleIncreaseQuantity={handleIncreaseQuantity}
                                  handleAddToCart={handleAddToCart}
                              />
                          ))
                      ) : (
                          <>
                              <p>We don't have any specials available at the moment.</p>
                          </>
                      )}
                  </div>
                  <Products/>
              </div>
          );
      }
    
}

export default Specials;