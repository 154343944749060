import ProductsCatalogue from "../views/ProductsCatalogue";

const ProductManagement = () => {
  return (
    <div className="container">
      <h2>Product Management</h2>
      <ProductsCatalogue/>
    </div>
  )
};

export default ProductManagement;