import { storage } from "../../config/firebaseConfig";
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject} from "firebase/storage";
import { 
    addDoc, 
    collection, 
    getDocs, 
    doc, 
    updateDoc, 
    deleteDoc, 
    query, 
    where
} from "firebase/firestore";
import { db } from "../../config/firebaseConfig";

/**
 * Gets the download URL for the image specified in the path
 * @param {string} path The path of the desired image
 * @returns {string} 
 */
export const getURL = async (path) => {
    try {
        const storageRef = ref(storage, path);
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        console.error("Error getting URL: ", error);
        throw error; // Re-throw the error so the caller can handle it if needed
    }
}

/**
 * Uploads an image to firebase storage
 * @param {string} file Uploaded Image file 
 * @param {string} path Image path
 */
//Upload image to firebase storage
export const uploadImage = async (file, path) => {
    try {
        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot.ref);
        })
    } catch {
        console.log("from prodConfig",file);
    }
}

/**
 * Creates a new product document in the firestore database
 * @param {Object} productData An object containing product details 
*/
export const createProduct = async (productData) => {
    try {
        console.log("Initializing product data:", productData);
        const productsCollection = collection(db, 'products');
        console.log("Adding products to collections...")
        await addDoc(productsCollection, {...productData});
    } catch (error) {
        console.error('Error adding product: ', error);
    }
};


/**
 * Reference to products collection
 */

export const productsRef = collection(db, 'products');

/**
 * Updates product details with id
 * @param {string} id Document id 
 * @param {Object} updatedData Updated values as an object
 */
export const updateProduct = async (id, updatedData) => {
    try{
        const productDoc = doc(productsRef, id)
        await updateDoc(productDoc, updatedData)
    }
    catch (error){
        console.log(id, updatedData)
        console.error("Update Error: ", error)
    }
}

export const deleteProduct = async (id) => {
    try{
        console.log("Retreving product...")
        const productDoc = doc(productsRef, id)
        console.log("Preparing to delete...")
        await deleteDoc(productDoc)
        console.log("Document deleted!!")
    } catch (error){
        console.log("Operation failed!",error)
    }
}

export const getImages = async () => {
    const listRef = ref(storage, 'images/products');
    try {
        const res = await listAll(listRef);
        const paths = await Promise.all(
            res.items.map(async (itemRef) => {
                const url = await getDownloadURL(itemRef);
                return {
                    "name": itemRef.name,
                    "path": itemRef.fullPath,
                    "url": url,
                };
            })
        );
        return paths;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const deleteImage = (imageName) => {
    const imageRef = ref(storage, `images/products/${imageName}`);
    try{
        deleteObject(imageRef)
        alert(`Deleted ${imageName}`)
    }catch(error){
        return error
    }
}