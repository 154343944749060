import { db } from '../config/firebaseConfig';
import { collection, query, orderBy, limit, getDocs, startAfter, where } from 'firebase/firestore';

export const fetchSpecials = async () => {
    const specialsQuery = query(
      collection(db, 'specials'),
        where('isActive', '==', true),
        );

    const querySnapshot = await getDocs(specialsQuery);
    const specialsList = querySnapshot.docs.map(doc => doc.data());
    return specialsList;

  };