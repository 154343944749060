import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../config/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";

const ProtectedRoute = ({ component: Component }) => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async (user) => {
      if (user) {
        const email = user.email;
        const q = query(collection(db, "admins"), where("email", "==", email));
        const adminsSnapshot = await getDocs(q);
        adminsSnapshot.forEach((doc) => {
          console.log("Admin email found:", doc.data().email);
        });

        if (!adminsSnapshot.empty) {
          console.log(adminsSnapshot);
          setIsAdmin(true);
        }
      }

      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log("User is signed in with UID:", user.uid);
        } else {
          console.log("No user is signed in.");
        }
      });
      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkAdmin(user);
      } else {
        setLoading(false);
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <div className="container">Loading...</div>
  }

  return (
    <>
      {isAdmin ? <Component /> : navigate("/login")}
    </>
  );
};

export default ProtectedRoute;