const About = () => {
    return (
        <div id="about-us" className="container">
            <h1>About Packaging Guy</h1>

            <div>
                <div>
                    <img src="path/to/your/logo.png" alt="Packaging Guy Logo"/>
                </div>

                <div >
                    <h2>Our Mission</h2>
                    <p>Packaging's mission is to be the leading provider of packaging solutions in Africa, committed to delivering high-quality products with exceptional customer service. Our goal is to help our customers secure and present their products in the best possible way, ensuring satisfaction and peace of mind.</p>
                </div>
            </div>

            <hr/>

            <div>
                <h2>Our Roots</h2>
                    <p>Packaging Guy was founded with the vision of providing reliable and affordable packaging materials to businesses and individuals across Africa. Since our inception, we have grown significantly, expanding our product range and services to meet the diverse needs of our customers.</p>
                    <p>Our journey began with a focus on essential packaging products such as refuse bags and plastic sheeting. Over time, we have added a variety of items including bubble wrap, packaging tapes, and custom packaging solutions. This growth has been fueled by our dedication to quality, innovation, and customer satisfaction.</p>

                    <p>In recent years, we have expanded our operations, establishing warehouses in key locations to ensure quick and efficient delivery. Our investment in state-of-the-art logistics and distribution systems allows us to serve our customers better and faster.</p>

                    <p>Looking ahead, Packaging Guy is committed to continuing our tradition of excellence. We are constantly exploring new products and technologies to enhance our offerings and provide even greater value to our customers.</p>

                    <div>
                        <h2>Contact Us</h2>
                        <p>Email: info@packagingguy.co.za</p>
                        <p>Phone: +27 123 456 7890</p>
                        <p>Address: 123 Packaging Street, Packaging City, South Africa</p>
                    </div>
            <div>

            </div>
                <img src="path/to/your/contact-image.png" alt="Contact Us"/>
            </div>
        </div>

    )
}

export default About;