import { useState } from "react";
import { deleteProduct } from "../controllers/productsController";
import useProducts from "../../hooks/useProducts";
import AddProductForm from "../../components/Forms/AddProductForm";
import Searchbar from '../../components/reusables/Searchbar';

const ProductsCatalogue = () => {
  const { loading, products, categories, selectedCategory, handleCategoryClick, loadMoreProducts } = useProducts();
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  if (isAddingProduct) {
    return (
      <div>
        <AddProductForm 
          onCancel={() => { setIsAddingProduct(false); }}
        />
      </div>
    );
  }

  if (isEditing) {
    return (
      <div>
        <AddProductForm 
          productToEdit={selectedProduct}
          onCancel={() => { setIsEditing(false); }}
        />
      </div>
    );
  }

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <div>
      <Searchbar onSearchResults={(results) => { /* Handle search results */ }} />

      <div className="category-tabs">
        {categories.map((category) => (
          <button 
            key={category} 
            onClick={() => handleCategoryClick(category)}
            className={`btn btn-primary btn-sm m-1 ${selectedCategory === category ? "active" : ""}`}
          >
            {category}
          </button>
        ))}
      </div>

      <button className="btn btn-primary" onClick={() => { setIsAddingProduct(true); }}>Add New Product</button>

      <table className="table table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Discount (%)</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td>
                <img
                  src={product.imageRef}
                  alt={product.productName}
                  className="img-fluid"
                  style={{ width: '100px', height: 'auto' }}
                />
              </td>

              <td>{product.productName}</td>
              <td>ZAR {product.price}</td>
              <td>{product.category}</td>
              <td>{product.discount}</td>
              <td>{product.description}</td>

              <td>
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => {
                    setIsEditing(true);
                    setSelectedProduct(product.id);
                  }}>Edit</button>

                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    deleteProduct(product.id);
                  }}>Delete Product</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {!loading && products.length > 0 && (
        <div className="col m-2">
          <button onClick={loadMoreProducts} className="btn btn-primary load-more-button">Load More</button>
        </div>
      )}
    </div>
  );
};

export default ProductsCatalogue;