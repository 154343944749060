import React, { useState, useEffect } from "react";
import Products from "./Products";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { Link } from "react-router-dom";
import { fetchSpecials } from "../../hooks/useSpecials";

function Home() {
    const [specials, setSpecials] = useState([]);

    useEffect(() => {
      const loadSpecials = async () => {
        const specialsData = await fetchSpecials();
        setSpecials(specialsData);
      };

      loadSpecials();
    }, []);

    return (
        <div className="container">
            {specials.length > 0 && (
                <div className="specials-section container">
                {specials.map((special, index) => (
                  <div className="card mb-4" key={index}>
                    <div className="row g-0">
                      <div className="col-md-6">
                        <img
                          className="img-fluid special-image"
                          src={special.imageRef}
                          alt={special.title}
                        />
                      </div>
                      <div className="col-md-6 d-flex flex-column justify-content-center p-3">
                        <h2 className="h4 mb-3">{special.title}</h2>
                        <p className="mb-2">{special.description}</p>
                        <p className="mb-3">
                          <b>From:</b> {special.startDate.toDate().toLocaleDateString()} -{" "}
                          {special.endDate.toDate().toLocaleDateString()}
                        </p>
                        <Link to="/specials" className="btn btn-primary">
                          View Deals
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>              
            )}

            <Products />
            
            <button className="btn position-fixed bottom-0 end-0 m-3">
                <a href="https://wa.link/qzr5w3" className="text-white">
                    <img src="icons/icons8-whatsapp.svg" alt="Whatsapp icon" />
                </a>
            </button>
        </div>
    );
}

export default Home;