import { useState, useEffect, Component } from "react";
import { doc, getDoc } from "firebase/firestore";
import { 
    uploadImage, 
    getURL, 
    createProduct, 
    updateProduct,
    productsRef
} from "../../admin/controllers/productsController";

/**
 * React component to add or update products to firestore
 * @param {string} productToEdit Product ID
 * @param {string} onCancel Cancels product update
 * @returns {Component}
 */

const AddProductForm = ({ productToEdit, onCancel, onSubmitSuccess }) => {

    const [imgUploadType, setImgUploadType] = useState("file");

    const returnOldValues = async () => {
        const productDoc = doc(productsRef, productToEdit);
        const docSnap = await getDoc(productDoc);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.error("No such document!");
            return null;
        }
    };

    const [formData, setFormData] = useState({
        imageRef: "",
        productName: "",
        description: "",
        category: "",
        price: 0,
        discount: 0,
    });
    
    const [productImage, setProductImage] = useState(null);

    useEffect(() => {
        if (productToEdit) {
            const fetchOldValues = async () => {
                const oldValues = await returnOldValues();
                if (oldValues) {
                    setFormData(oldValues);
                }
            };
            fetchOldValues();
        }
    }, [productToEdit]);

    const handleChange = (e) => {  
      const { name, value, type, files } = e.target;
      if (type === "file" && files.length > 0) {
          const file = files[0];
          setProductImage(file);
      } else {
          setFormData((prevData) => ({
              ...prevData,
              [name]: name == "discount" ? +value : value,
          }));
      }
    };    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (imgUploadType === "file" && productImage) {
          let url = `images/products/${formData.productName}`;
          await uploadImage(productImage, url);
          formData.imageRef = await getURL(url);
      } else if (imgUploadType === "url" && formData.productImage) {
          formData.imageRef = formData.productImage;
      }
    
      formData.discount = parseFloat(formData.discount) || 0;
    
      try {
          if (productToEdit) {
              await updateProduct(productToEdit, formData);
              console.log('Product updated', formData);
          } else {
              await createProduct(formData);
              console.log('Product added', formData);
          }
    
          setFormData({});
          if (onSubmitSuccess) onSubmitSuccess();
      } catch (error) {
          console.error('Error updating/adding product:', error);
      }
    };

    return (
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="productName">Product Name:</label>
              <input
                type="text"
                id="productName"
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="productImage">Product Image:</label>

              {imgUploadType == "url" &&
                <input 
                  type="text"
                  id="productImage"
                  name="productImage"
                  onChange={handleChange}
                  className="form-control"
                />
              }

              {imgUploadType == "file" &&
                <input
                  type="file"
                  id="productImage"
                  name="productImage"
                  onChange={handleChange}
                  className="form-control-file"
                />
              }

              <button onClick={()=>{
                imgUploadType == "file" ? setImgUploadType("url") : setImgUploadType("file");
              }}>{imgUploadType == "file" ? "Use url instead" : "Upload image from files"}</button>
            </div>

            <div className="form-group">
              <label htmlFor="price">Price:</label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                className="form-control"
                />
            </div>

            <div className="form-group">
              <label htmlFor="category">Category:</label>
              <input
                type="text"
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description:</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                className="form-control"
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="discount">Discount %:</label>
              <input
                type="number"
                id="discount"
                name="discount"
                value={formData.discount}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary mr-2">
                {productToEdit ? 'Update Product' : 'Add Product'}
              </button>
              <button type="button" onClick={onCancel} className="btn btn-secondary">
                Cancel
              </button>
            </div>
          </form>
        </div>
    );
};

export default AddProductForm;