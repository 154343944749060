import { useState, useEffect } from "react";
import { db } from "../../config/firebaseConfig";
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import SpecialsForm from "../views/SpecialsForm";

const ContentEditor = () => {
    const [specials, setSpecials] = useState([]);
    const [isAddingSpecial, setIsAddingSpecial] = useState(false);
    const [editingSpecial, setEditingSpecial] = useState(null);
    const [newSpecialDetails, setNewSpecialDetails] = useState({
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        isActive: false,
        imageRef: '',
        minRequirements: 0,
        minCartItems: 0,
        appliedDiscount: 0
    });

    useEffect(() => {
        const fetchSpecials = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "specials"));
                const fetchedSpecials = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    fetchedSpecials.push({
                        id: doc.id,
                        ...data,
                        startDate: data.startDate.toDate().toLocaleDateString(),
                        endDate: data.endDate.toDate().toLocaleDateString()
                    });
                });
                setSpecials(fetchedSpecials);
            } catch (error) {
                console.error("Error fetching specials: ", error);
            }
        };

        fetchSpecials();
    }, []);

    const handleEditSpecial = (special) => {
        setEditingSpecial(special);
        setNewSpecialDetails({
            title: special.title,
            description: special.description,
            startDate: special.startDate,
            endDate: special.endDate,
            isActive: special.isActive,
            imageRef: special.imageRef,
            minRequirements: special.minRequirements,
            minCartItems: special.minCartItems,
            appliedDiscount: special.appliedDiscount
        });
    };

    const handleSaveSpecial = async (specialId) => {
        try {
            const specialRef = doc(db, "specials", specialId);
            const updatedSpecial = {
                ...newSpecialDetails,
                startDate: new Date(newSpecialDetails.startDate),
                endDate: new Date(newSpecialDetails.endDate),
                minRequirements: Number(newSpecialDetails.minRequirements),
                minCartItems: Number(newSpecialDetails.minCartItems),
                appliedDiscount: Number(newSpecialDetails.appliedDiscount)
            };
            await updateDoc(specialRef, updatedSpecial);
            
            const updatedSpecialWithFormattedDates = {
                ...updatedSpecial,
                startDate: updatedSpecial.startDate.toLocaleDateString(),
                endDate: updatedSpecial.endDate.toLocaleDateString()
            };
    
            setSpecials(specials.map(special => 
                special.id === specialId ? { ...special, ...updatedSpecialWithFormattedDates } : special
            ));
            setEditingSpecial(null);
        } catch (error) {
            console.error("Error updating special: ", error);
        }
    };

    const handleDeleteSpecial = async (specialId) => {
        try {
            await deleteDoc(doc(db, "specials", specialId));
            setSpecials(specials.filter(special => special.id !== specialId));
        } catch (error) {
            console.error("Error deleting special: ", error);
        }
    };

    const handleAddSpecial = async () => {
        try {
            const newSpecial = {
                ...newSpecialDetails,
                startDate: new Date(newSpecialDetails.startDate),
                endDate: new Date(newSpecialDetails.endDate)
            };
            const docRef = await addDoc(collection(db, "specials"), newSpecial);
            setSpecials([...specials, {
                id: docRef.id,
                ...newSpecial,
                startDate: newSpecial.startDate.toLocaleDateString(),
                endDate: newSpecial.endDate.toLocaleDateString()
            }]);
            setNewSpecialDetails({
                title: '',
                description: '',
                startDate: '',
                endDate: '',
                isActive: false,
                imageRef: '',
                minRequirements: 0,
                minCartItems: 0,
            });
        } catch (error) {
            console.error("Error adding special: ", error);
        }
    };

    if(isAddingSpecial){
        if(editingSpecial){
            setEditingSpecial(null)
        }

        return (
            <SpecialsForm
                specialDetails={newSpecialDetails}
                setSpecialDetails={setNewSpecialDetails}
                handleSave={handleAddSpecial}  // Function to save a new special
                handleCancel={() => setIsAddingSpecial(false)}
            />
        )
    }

    return (
        <div>
            <h3>Content Editor</h3>
            <button className="btn btn-sm btn-primary" onClick={() => setIsAddingSpecial(true)}>Add Special</button>
            <div className="specials-list">
                {specials.map(special => (
                    <div key={special.id} className="special-item">
                        {editingSpecial && editingSpecial.id === special.id ? (
                            <SpecialsForm
                                specialDetails={newSpecialDetails}
                                setSpecialDetails={setNewSpecialDetails}
                                handleSave={() => handleSaveSpecial(editingSpecial.id)}  // Function to save the edited special
                                handleCancel={() => setEditingSpecial(null)}
                                isEditMode={true}
                            />
                        ) : (
                            <div className="card p-1 m-1">
                                <h4 className="text-center">{special.title}</h4>
                                <div className="row">
                                    <div className="col">
                                        <img src={special.imageRef} alt={special.title} className="special-image" height={"300px"} width={"300px"}/>
                                    </div>
                                    <div className="col">
                                        <p><b>From:</b> {special.startDate} - {special.endDate}</p>
                                        <p><b>Status:</b> {special.isActive ? "Active" : "Inactive"}</p>
                                        <p><b>Description:</b> {special.description}</p>
                                        <button className="btn btn-sm btn-primary m-1" onClick={() => handleEditSpecial(special)}>Edit</button>
                                        <button className="btn btn-sm btn-danger m-1" onClick={() => handleDeleteSpecial(special.id)}>Remove</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContentEditor;