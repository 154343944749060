import { Link, useLocation } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import "../../styles/Navbar.css";

/**
 * NavBar component.
 *
 * This component renders the navigation bar for the application. It includes links to
 * the home page, products page, and shopping cart. Depending on the user's authentication
 * status, it conditionally renders a login link or an account link.
 *
 * @component
 * @returns {JSX.Element} The rendered navigation bar.
 */

const NavBar = () => {

    const location = useLocation();
    const {isSignedIn} = useAuth();

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <div>
            <img className="navbar-logo" src="./pg-logo.svg" height="80px"/>
            <Link className="navbar-brand" to="/">Packaging Guy</Link>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/products' ? 'active' : ''}`} to="/products">Products</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/shopping-cart' ? 'active' : ''}`} to="/shopping-cart">Cart</Link>
            </li>

            {!isSignedIn && (
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`} to="/login">Login</Link>
              </li>
            )}

            {isSignedIn && (
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/account' ? 'active' : ''}`} to="/account">Account</Link>
              </li>
            )}
          </ul>
        </div>
        </div>
      </nav>
    );
}

export default NavBar;