import { useEffect, useState } from 'react';
import ProductManagement from "./pages/ProductManagement";
import OrderManagement from "./pages/OrderManagement";
import Gallery from "./pages/Gallery";
import ContentEditor from './pages/ContentEditor';

const AdminDashboard = () => {
   const [currentTab,setTab] = useState("product-management");
   const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

   const changeTab = (selectedTab) => {
        if(selectedTab == "product-management"){
            setTab("product-management")
        }else if(selectedTab == "orders"){
            setTab("orders")
        }else if(selectedTab == "gallery"){
            setTab("gallery")
        }else if(selectedTab == "content-editor"){
            setTab("content-editor")
        }
    }

    useEffect(()=>{
        window.onresize = () => {
            setDeviceWidth(window.innerWidth);
        }
    },[])

    if(deviceWidth < 700){
        return (
            <div className='container'>
                <h1>You will need a bigger screen to view this page.</h1>
                <sub>Try logging in on desktop.</sub>
            </div>
        )
    }

    return (
        <div className="container">
            <div className="row dashboard">
                <div className="col-sm-3 dashboard-controls">
                    <div className="row">
                        <h3>Admin Dashboard</h3>
                    </div>
                    <div className="row">
                        <button className={currentTab == "product-management" ? "btn btn-secondary mb-2" : "btn btn-primary mb-2"} onClick={()=>{changeTab("product-management")}}>Product Management</button>
                        <button className={currentTab == "orders" ? "btn btn-secondary mb-2" : "btn btn-primary mb-2"} onClick={()=>{changeTab("orders")}}>Orders</button>
                        <button className={currentTab == "gallery" ? "btn btn-secondary mb-2" : "btn btn-primary mb-2"} onClick={()=>{changeTab("gallery")}}>Gallery</button>
                        <button className={currentTab == "content-editor" ? "btn btn-secondary mb-2" : "btn btn-primary mb-2"} onClick={()=>{changeTab("content-editor")}}>Content Editor</button>
                    </div>
                </div>
                
                <div className="col">
                    {currentTab == "product-management" && <ProductManagement/>}
                    {currentTab == "orders" && <OrderManagement/>}
                    {currentTab == "gallery" && <Gallery/>}
                    {currentTab == "content-editor" && <ContentEditor/>}
                </div>
            </div>
        </div>
    );
    
};

export default AdminDashboard;