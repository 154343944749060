import { db } from "../../config/firebaseConfig";
import { 
   collection,  
   addDoc, 
   serverTimestamp, 
   getDoc,
   updateDoc,
   doc
} from "firebase/firestore";

const ordersCollection = collection(db, "orders");

export const createOrder = async (userId, userName, phoneNumber, address, items, total, transactionRef) => {
   try {
      const orderData = {
         userId: userId,
         userName: userName,
         phoneNumber: phoneNumber,
         address: address,
         items: items,
         total: total,
         transactionRef: transactionRef,
         status: "pending", // Default order status
         date: serverTimestamp(),
      };

      const docRef = await addDoc(ordersCollection, orderData);
   } catch (error) {
      console.error("Error adding order: ", error);
   }
};

export const getOrderDetails = async (orderId) => {
   try {
      const orderDocRef = doc(db, "orders", orderId); // Use `db` to specify the collection and document ID
      const orderSnapshot = await getDoc(orderDocRef);

      if (orderSnapshot.exists()) {
         const orderData = orderSnapshot.data();
         return orderData;
      } else {
         console.log("No such document!");
         return null;
      }
   } catch (error) {
      console.error("Error getting order details:", error);
   }
};

export const updateUsersOrder = async (ref, action) => {
   try {
     const orderDoc = doc(collection(db, "orders"), ref);
     await updateDoc(orderDoc, {
       status: action
     });
   } catch (error) {
     console.error("Error updating order:", error);
   }
 };

export const getOrders = async () => {
   const q = query(collection(db, "orders"), limit(10));
   const ordersSnapshot = await getDocs(q);
   return ordersSnapshot.docs.map(doc => ({...doc.data(), id: doc.id }));
   setLastVisibleDoc(ordersSnapshot.docs[ordersSnapshot.docs.length - 1]);
 }