import { useState } from "react";
import { updateUsersOrder } from "../controllers/ordersController";

const OrderView = ({order, orderId, setIsViewingOrder }) => {

    const [status, setStatus] = useState(order.status);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await updateUsersOrder(orderId, status);  // Ensure orderId is passed correctly
            console.log('Order status updated successfully!', orderId);
        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };

    return (
        <div className="container">
  <div className="row-sm">
    <button className="btn btn-secondary btn-sm m-1" onClick={() => setIsViewingOrder(false)}>Back to Orders</button>
  </div>
  <div className="row">
    <div className="col">
      <h3>Order Details</h3>
      <div className="mb-3">
        <p><strong>Transaction Ref:</strong> {order.paymentRef}</p>
        <p><strong>User:</strong> {order.name}</p>
        <p><strong>Email:</strong> {order.email}</p>
        <p><strong>Phone Number:</strong> {order.number}</p>
        <p><strong>Ordered on:</strong> {new Date(order.createdAt.seconds * 1000).toLocaleDateString()}</p>
        <p><strong>Status:</strong> {order.status}</p>
        <p><strong>Shipping Address:</strong> {order.address.street}, {order.address.suburb}, {order.address.city}, {order.address.zip}</p>
        <p><strong>Items:</strong></p>
        <ul className="list-unstyled">
          {order.items.map(item => (
            item.discount > 0 ? (
              <li key={item.id}>{item.productName} - R{item.price - item.discount} x {item.quantity} ({item.discount}% off)</li>
            ) : (
              <li key={item.id}>{item.productName} - R{item.price} x {item.quantity}</li>
            )
          ))}
        </ul>
      </div>
      <p><strong>Total:</strong> R{order.cartTotal}</p>
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="form-group">
          <label htmlFor="status"><b>Update Order Status:</b></label>
          <select
            id="status"
            value={status}
            onChange={handleStatusChange}
            className="form-control"
          >
            <option value="pending">Pending</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary mt-2">Update Status</button>
      </form>
    </div>
  </div>
</div>

    )
}

export default OrderView;