import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from "./components/Forms/SignUp";
import Login from "./components/Forms/Login";
import Home from "./components/pages/Home";
import Account from "./components/pages/Account";
import Products from "./components/pages/Products";
import ProductDetails from "./components/pages/ProductDetails";
import Cart from "./components/pages/Cart";
import Checkout from "./components/pages/Checkout";
import AddProductForm from "./components/Forms/AddProductForm";
import ProtectedRoute from "./admin/ProtectedRoute";
import AdminDashboard from "./admin/AdminDashboard";
import NavBar from "./components/reusables/Navbar";
import ContactUs from "./components/pages/ContactUs";
import Footer from "./components/reusables/Footer";
import About from "./components/pages/About";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Specials from './components/pages/Specials';
import { CheckoutProvider } from './contexts/CheckoutContext';

/**
 * Main application component.
 * 
 * This component sets up the React Router for the application, defining routes
 * for various pages and components. It includes routes for home, signup, login,
 * account, products, shopping cart, add product form, and admin dashboard.
 *
 * @component
 * @example
 * return (
 *   <App />
 * )
 */

function App() { 
  return(
  <>
    <Router>
      <NavBar className="row"/>
      <CheckoutProvider>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/account" element={<Account/>}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/products/:productId" element={<ProductDetails/>}/>
          <Route path="/shopping-cart" element={<Cart/>}/>
          <Route path="/contact" element={<ContactUs/>}/>
          <Route path="/checkout" element={<Checkout/>}/>
          <Route path="/specials" element={<Specials/>}/>
          <Route path="/add-product-form" element={<AddProductForm/>}/>
          <Route path="/admin" element={<ProtectedRoute component={AdminDashboard}/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/privacy" element={<PrivacyPolicy/>}/>
        </Routes>
      </CheckoutProvider>
      <Footer className="row"/>
    </Router>
  </>
  )
}

export default App;