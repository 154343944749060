import { useState, useEffect } from "react";
import { collection, query, orderBy, limit, getDocs, startAfter, where, Timestamp } from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { getOrderDetails } from "../controllers/ordersController";
import OrderView from "../views/OrderView";

const OrderManagement = () => {
    const [orders, setOrders] = useState([]);
    const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isViewingOrder, setIsViewingOrder] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [statuses, setStatuses] = useState(["all", "pending", "completed", "shipped", "cancelled"]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        fetchOrders();
    }, [selectedStatus, startDate, endDate]);

    const fetchOrders = async () => {
        setLoading(true);
        let ordersQuery = query(collection(db, "orders"), limit(10));
        
        if (selectedStatus !== "all") {
            ordersQuery = query(ordersQuery, where("status", "==", selectedStatus));
        }
        
        if (startDate && endDate) {
            ordersQuery = query(ordersQuery, where("createdAt", ">=", Timestamp.fromDate(new Date(startDate))));
            ordersQuery = query(ordersQuery, where("createdAt", "<=", Timestamp.fromDate(new Date(endDate))));
        }
        
        const ordersSnapshot = await getDocs(ordersQuery);
        setOrders(ordersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLastVisibleDoc(ordersSnapshot.docs[ordersSnapshot.docs.length - 1]);
        setLoading(false);
    };

    const fetchMoreOrders = async () => {
        if (!lastVisibleDoc) return;
        let ordersQuery = query(collection(db, "orders"), orderBy("createdAt"), startAfter(lastVisibleDoc), limit(10));
        
        if (selectedStatus !== "all") {
            ordersQuery = query(ordersQuery, where("status", "==", selectedStatus));
        }
        
        if (startDate && endDate) {
            ordersQuery = query(ordersQuery, where("createdAt", ">=", Timestamp.fromDate(new Date(startDate))));
            ordersQuery = query(ordersQuery, where("createdAt", "<=", Timestamp.fromDate(new Date(endDate))));
        }
        
        const ordersSnapshot = await getDocs(ordersQuery);
        setOrders([...orders, ...ordersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))]);
        setLastVisibleDoc(ordersSnapshot.docs[ordersSnapshot.docs.length - 1]);
    };

    const viewOrderDetails = async (orderId) => {
        const orderData = await getOrderDetails(orderId);
        setSelectedOrder(orderData);
        setIsViewingOrder(true);
        setOrderId(orderId);
    };

    const handleStatusClick = (status) => {
        setSelectedStatus(status);
    };

    const handleDateChange = () => {
        fetchOrders();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isViewingOrder) {
        return (
            <OrderView
                setIsViewingOrder={setIsViewingOrder}
                orderId={orderId}
                order={selectedOrder}
            />
        );
    }

    return (
        <div>
            <h3>Orders</h3>
            <div className="status-filters">
                {statuses.map(status => (
                    <button
                        key={status}
                        onClick={() => handleStatusClick(status)}
                        className={`btn btn-primary btn-sm m-1 ${selectedStatus === status ? "active" : ""}`}
                    >
                        {status}
                    </button>
                ))}
            </div>

            <div className="date-filters">
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
                <button onClick={handleDateChange} className="btn btn-primary btn-sm">
                    Apply Date Filter
                </button>
            </div>

            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th>Transaction Ref</th>
                        <th>User</th>
                        <th>Items</th>
                        <th>Status</th>
                        <th>Ordered On</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td>
                                <a
                                    href={`https://dashboard.paystack.com/#/search?model=transactions&query=${order.paymentRef}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {order.paymentRef}
                                </a>
                            </td>
                            <td>{order.name}</td>
                            <td>
                                {order.items.map(item => (
                                    <ul key={item.id} className="list-unstyled">
                                        <li>{item.productName} - R{item.price} x {item.quantity}</li>
                                    </ul>
                                ))}
                                <strong>Total: {order.cartTotal}</strong>
                            </td>
                            <td>{order.status}</td>
                            <td>{new Date(order.createdAt.seconds * 1000).toLocaleDateString()}</td>
                            <td>
                                <button className="btn btn-info btn-sm" onClick={() => viewOrderDetails(order.id)}>
                                    View Order
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button onClick={fetchMoreOrders}>Load More</button>
        </div>
    );
};

export default OrderManagement;